import React from 'react'
import { Button } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//     palette: {
//       primary: '#fff',
//     },
//   });

export default function Buttons({ text, hoverText, type, bgColor, functions, hoverColor, hoverBorder, color, border, padding, icons, width, weight, size, frontIcon  }) {
    return (
<Button 
            type={type}
            sx={
                {
                    display: 'flex',
                    gap: '16px',
                    padding: padding ? padding : '9px 17px',
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    background: bgColor,
                    color: color,
                    fontWeight: weight ? weight : 600,
                    fontSize: size ? size : '16px',
                    fontFamily: 'inherit',
                    width: width,
                    border: border,
                    transition: 'all 0.3s ease-in-out',
                    "&:hover": {
                      backgroundColor: hoverColor,
                      color: hoverText,
                      border: hoverBorder
                    }
                }
            }
            onClick={functions}
        >
            {icons}
            {text}
            {frontIcon}
        </Button>
    )
}
