import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/img/logo2.png'
import Logo2 from '../../assets/img/logo.png'
import Buttons from '../button'
import { ReactComponent as Menu } from '../../assets/svg/menu.svg'

export default function Navigation() {
    const navigate = useNavigate();

    const getStarted = () => {
        navigate('/getstarted');
    }
    
    return (
        <div className='flex justify-between items-center desktop:pb-6 pb-3 synergy-nav'>
            <div className='cursor-pointer'>
                <img src={Logo} alt='synerygy logo' className='desktop:block hidden' onClick={() => navigate('/')} />
                <img src={Logo2} alt='synerygy logo' className='desktop:hidden block w-[108px]' onClick={() => navigate('/')}/>
            </div>
            <div className='gap-8 text-[16.5px] font-medium capitalize text-light cursor-pointer desktop:flex hidden'>
                <p>About Us</p>
                <p onClick={() => navigate('/#connection')}>Success stories</p>
                <p>Partnership</p>
                {/* <p>Job Board</p> */}
            </div>
            <div className='desktop:flex hidden'>
                <Buttons text='get started - it’s free' bgColor='#EFF4F5' hoverColor='#024355' hoverText='#FFFFFF' color='#00212D' functions={getStarted}/>
            </div>
            <Menu className='desktop:hidden block'/>
        </div>
    )
}
