import React, {useEffect, useState} from 'react'
import Nav from '../components/nav/nav';
import Hero from '../components/hero/hero';
import Upskill from '../components/upskill/upskill';
import Landdreamjob from '../components/upskill/landdreamjob';
import Joinexcitingcircles from '../components/upskill/joinexcitingcircles';
import Success from '../components/success/success';
import Hire from '../components/hire/hire';
import Platform from '../components/platform/platform';
import Download from '../components/download/download';
import Footer from '../components/footer/footer';
import Foot from '../components/footer/foot';
import { useLocation } from 'react-router-dom';
import Hamburger from '../components/hamburger';

export default function Home({hamburger, setHamburger}) {

    const location = useLocation()
//    useEffect(() => {
//     window.location.reload();
//    },[])

    useEffect(() => {
        if (location.hash) {
          const targetElement = document.querySelector(location.hash);
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, [location]);

    return (
        <div className='bg-background relative' style={hamburger ? {height:'100vh', overflow:'hidden'}: {}}>
          {hamburger && <Hamburger setHamburger={setHamburger} />}
            <Nav hamburger={hamburger} setHamburger={setHamburger} />
            <Hero />
            <Upskill />
            <Landdreamjob />
            <Joinexcitingcircles />
            {/* <Success /> */}
            <Hire />
            <Platform />
            <Download />
            {/* <Footer />
            <Foot /> */}
        </div>
    )
}
