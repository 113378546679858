import React, { useState } from 'react'
import Box from '../box'
import { ReactComponent as Tick } from '../../assets/svg/🦆 icon _tick circle_.svg'
import Buttons from '../button';
import { useNavigate } from 'react-router-dom';

export default function UsernameForm() {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [username, setUsername] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!username) {
            setError(true);
        }

        if (error) {
            setError(false);
        }
        
        if (username.length <= 5 ) {
            setError(true);
        } else {
            setSuccess(true);
            const state = {
                username: username
            }
            setTimeout(() => {
                navigate('/welcome', { state })
            }, 500)
        }
    };
    
    return (
        <Box>
            <h1 className='mb-2 text-white text-center text-[32px] font-semibold'>Welcome Toyosi, </h1>
            <h3 className='text-center text-white opacity-[0.6]'>Let’s win together!</h3>
            <form onSubmit={handleSubmit}>
                <div className='desktop:my-[72px] my-[121px]'>
                    <h4 className='text-[20px] text-center text-white'>Pick a <span className='italic'>username</span></h4>
                    <div className='mt-6 py-2 px-4 justify-center'>
                        <div className='flex'>
                            <input 
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className={`${error ? 'border-[#F56A6A]' : 'border-gray'} w-full text-white bg-background border-[0.8px] rounded-[16px] px-4 py-3`} />
                            {success && <Tick className='-ml-10 my-[12.5px]' />}
                            {error && <p className='-ml-10 my-[12.5px] text-[19px] font-semibold text-[#F56A6A]'>!</p>}
                        </div>
                        {error && (<p className='mt-2 text-[#F56A6A]'>Username not available</p>)}
                    </div>
                </div>
                <div className='flex justify-center'>
                    <Buttons text='Proceed' hoverColor='#F56A6A' type='submit' width='334px' weight='600' padding='14px 48px' color='#00141B' bgColor='#FBA599'/>
                </div>
            </form>
        </Box>
    )
}
