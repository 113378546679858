import React from 'react'
import Plat from '../../assets/img/plat.png'
import Plat2 from '../../assets/img/plat2.png'
import Plat3 from '../../assets/img/plat3.png'
import Plat4 from '../../assets/img/plat4.png'

export default function Platform() {
    return (
        <div className='desktop:px-20 px-[29px] desktop:py-[100px] py-[50px] platform'>
            <h2 className='tab:text-[40px] text-[30px]'>All your needs in <span className='text-primary'>Laundreo</span></h2>

            <div className='mt-16 desktop:flex tab:gap-4 gap-2 justify-center grid grid-cols-2'>
                <div>
                    <div className='platform-card py-6 px-[21.647px] phone:py-8 phone:px-10 phone:gap-[7.22px] gap-[3.907px]'>
                        <div className='platform-box w-[88px] h-[88px] phone:w-[188px] phone:h-[156px]'>
                            <img src={Plat} alt='synergy platform' />
                        </div>
                    </div>
                    <p className='tab:mt-8 mt-4 font-semibold capitalize text-white text-center desktop:text-[16px] text-[10px]'>Standard Laundry Services</p>
                </div>
                
                <div>
                    <div className='platform-card py-6 px-[21.647px] phone:py-8 phone:px-10 phone:gap-[7.22px] gap-[3.907px]'>
                        <div className='platform-box w-[88px] h-[88px] phone:w-[188px] phone:h-[156px]'>
                            <img src={Plat2} alt='synergy platform' />
                        </div>
                    </div>
                    <p className='tab:mt-8 mt-4 font-semibold capitalize desktop:text-[16px] text-[10px] text-white text-center'>Specialized Fabric Care</p>
                </div>
                
                <div className='tab:mt-0 mt-3'>
                    <div className='platform-card py-6 px-[21.647px] phone:py-8 phone:px-10 phone:gap-[7.22px] gap-[3.907px]'>
                        <div className='platform-box w-[88px] h-[88px] phone:w-[188px] phone:h-[156px]'>
                            <img src={Plat3} alt='synergy platform' />
                        </div>
                    </div>
                    <p className='tab:mt-8 mt-4 font-semibold capitalize desktop:text-[16px] text-[10px] text-white text-center'>Household and Accessories</p>
                </div>

                <div className='tab:mt-0 mt-3'>
                    <div className='platform-card py-6 px-[21.647px] phone:py-8 phone:px-10 phone:gap-[7.22px] gap-[3.907px]'>
                        <div className='platform-box w-[88px] h-[88px] phone:w-[188px] phone:h-[156px]'>
                            <img src={Plat4} alt='synergy platform' />
                        </div>
                    </div>
                    <p className='tab:mt-8 mt-4 font-semibold capitalize desktop:text-[16px] text-[10px] text-white text-center'>Business Services</p>
                </div>
            </div>
        </div>
    )
}
