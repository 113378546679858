import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as Menu } from '../../assets/svg/menu.svg'
import Buttons from '../button'
import NavPop from './navPop'

export default function Nav({ user,page, setHamburger }) {
  const navigate = useNavigate();
  const location = useLocation()
  const [menu, setMenu] = useState(false);

  const getStarted = () => {
    navigate('/getstarted');
  }

  const handleMenu = () => {
    setMenu(!menu);
  }
  
  return (
    <div className='desktop:py-6 py-4 desktop:px-[43.2px] px-6 flex justify-between items-center'>
      <div className='flex items-center gap-16 cursor-pointer'>
        <p className='text-[29.681px] font-bold text-[#EFF4F5]' onClick={() => navigate('/')}>Laundreo</p>
        <div className='desktop:flex hidden gap-8 text-[16.5px] font-medium capitalize text-light cursor-pointer'>
          { <>
              <p onClick={() => navigate('/')}>Coming Soon!</p>
            </>
          }
        </div>
      </div>
      <div className='gap-2 items-center desktop:block hidden'>
        <Buttons text='Contact us' bgColor='#EFF4F5' hoverText='#FFFFFF' hoverColor='#024355' color='#00141B'/>
        {/* <Buttons text='Contact us' bgColor='#EFF4F5' hoverText='#FFFFFF' hoverColor='#024355' color='#00141B' functions={getStarted}/> */}
      </div>
      {menu && <NavPop />}
      <Menu className='desktop:hidden block' />
      {/* <Menu className='desktop:hidden block' onClick={() => setHamburger(true)}/> */}
    </div>
  )
}
