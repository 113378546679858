import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Background from '../../assets/img/bg2.png'
import Buttons from '../button'
import { ReactComponent as Apple } from '../../assets/svg/apple.svg';
import { ReactComponent as Googleplay } from '../../assets/svg/google-play.svg';
import { Link } from 'react-router-dom';

export default function Download() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        if (inView) {
          setIsAnimated(true);
        }
    }, [inView]);
    
    return (
        <div className='desktop:px-20 px-[29px] desktop:py-[120px] py-[50px]'>
            <div className='download flex flex-col desktop:flex-row'>
                <div className='desktop:w-[46%] flex flex-col justify-center desktop:pl-12 desktop:mt-0 mt-[71px] desktop:px-0 px-[13px]'>
                    <h1 className='mini:text-[32.9px] text-[23px] text-center desktop:text-start font-bold mb-[21.33px] capitalize'>Like what you see? There’s more on the app</h1>
                    <p className='mini:text-[15.1px] text-[13px] font-medium capitalize text-center desktop:text-start'>Experience laundry at your fingertips with the Laundreo app. Simplify your laundry routine and access our services whenever and wherever you need them. Download the app today and enjoy a host of benefits: </p>
                    <div ref={ref} className={`animate__animated animate__slow ${isAnimated ? 'animate__headShake' : ''} desktop:mt-[42px] desktop:mb-0 mb-[23px] mt-8 flex gap-6 flex-col desktop:flex-row items-center`}>
                        <Link><Buttons text='Get on Iphone' hoverColor='#000000' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #024355' icons={<Apple />} /></Link>
                        <Link><Buttons text='Get on Android' hoverColor='#000000' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #024355' icons={<Googleplay />} /></Link>
                        {/* <Link to={'https://apps.apple.com/ng/app/synergy-ng/id6447760204'}><Buttons text='Get on Iphone' hoverColor='#000000' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #024355' icons={<Apple />} /></Link>
                        <Link to={'https://play.google.com/store/apps/details?id=com.synergyng.synergy'}><Buttons text='Get on Android' hoverColor='#000000' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #024355' icons={<Googleplay />} /></Link> */}
                    </div>
                </div>
                <div>
                    <img src={Background} alt='synergy background' className='rounded-br-[40px]' />
                </div>
            </div>
        </div>
    )
}
