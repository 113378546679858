import React from 'react'
import { ReactComponent as Arrow } from '../../assets/svg/arrow3.svg';
import { ReactComponent as Arrow2 } from '../../assets/svg/arrow4.svg';
import About from '../../assets/img/hire.png'
import { useNavigate } from 'react-router-dom';

export default function Hire() {

    const navigate = useNavigate()
    return (
        <div className='flex mids:flex-row flex-col-reverse items-center desktop:pl-20 pl-6 desktop:pt-0 pt-[50px] desktop:pb-[100px] pb-[50px] mids:gap-20 gap-10'>
            <div className='mid:w-[50%] mids:pr-0 pr-6'>
                <h2 className='mb-5 tab:text-[40px] text-[32px] text-white font-bold capitalize'>Introducing <span className='text-gradient'> Laundreo Business</span></h2>
                <p className='text-justify tab:text-[18px] text-[14px] text-white'>At Laundreo, we understand that businesses have unique laundry needs, especially when it comes to maintaining the cleanliness and professionalism of their employees' uniforms and workwear.</p>
                <br />
                <p className='tab:text-[18px] text-[14px] text-justify text-white'>That's why we're thrilled to introduce our specialized Business Solutions – a service tailored to meet the demands of businesses like yours.</p>
                <div className='flex mt-12 gap-6 tab:justify-start justify-center'>
                    <div className='flex gap-2 items-center p-4 rounded-lg cursor-pointer hover:motion-safe:animate-pulse bg-[#9BBFB5] phones:justify-start justify-center phones:w-auto w-[50%]'>
                    {/* <div className='flex gap-2 items-center p-4 rounded-lg cursor-pointer hover:motion-safe:animate-pulse bg-[#9BBFB5] phones:justify-start justify-center phones:w-auto w-[50%]' onClick={() => navigate('/get-access')}> */}
                        <p className='font-medium text-[14px] text-background'>Join the Waitlist</p>
                        <Arrow2 />
                    </div>
                </div>
            </div>
            <div className='hire mid:w-[50%]'>
                <img src={About} alt='synergy hire' className='mt-[70px]'/>
            </div>
        </div>
    )
}
