import React, { useEffect, useState } from "react";
import Nav from "../components/nav/nav";
import Buttons from "../components/button";
import {ReactComponent as ArrowRightBlack} from "../../src/assets/svg/arrow-right-black.svg"
import Footer from "../components/footer/footer";
import Foot from "../components/footer/foot";
import { useInView } from "react-intersection-observer";
import Hamburger from "../components/hamburger";


export default function About({hamburger, setHamburger}){

    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [flag, viewFlag] = useInView({
        triggerOnce: true,
    });

    const [start, viewStart] = useInView({
        triggerOnce: true,
    });
    

    const [isAnimated, setIsAnimated] = useState(false);
    const [flagView, setFlagView] = useState(false)
    const [buttonView, setButtonView] = useState(false)



    useEffect(() => {
        if (inView) {
          setIsAnimated(true);
        }
        if (viewFlag){
            setFlagView(true)
        }
        if (viewStart){
            setButtonView(true)
        }
    }, [inView, viewFlag, viewStart]);

    return (
        <div className='bg-background auth relative'>
            {hamburger && <Hamburger setHamburger={setHamburger} />}
            <Nav page={'about'} hamburger={hamburger} setHamburger={setHamburger} />
            <div className="pt-[48px] pb-[33px] px-[29px]">
                <p className={`hidden tab:block text-[22.4px] mb-[16px] font-semibold about-about`}>ABOUT SYNERGY</p>
                <h1 className="font-semibold tab:text-[40px] phone:text-[45px] phone:text-left mid:text-[58px] mids:text-[65px] desktop:text-[80px] text-[22px] phones:text-[24px] text-center text-white" style={{lineHeight:'120%'}}>Empowering <span className="text-primary opacity-40">Young Talent <br/> To Achieve </span>Big Dreams</h1>
            </div>
            <div className="dekstop:px-[40px] desktop:my-[64px]">
                    <div className="grid place-items-center animate__animated animate__fadeInLeft animate__slow">
                        <img src="/images/about-trio.svg" alt="man smiling" className="w-[500px] phones:w-[100%]"/>
                    </div>
            </div>
            <div className="flex flex-col gap-y-[35px] mid:flex-row-reverse mid:p-[80px] desktop:gap-x-[100px]">
               <div className="p-[24px] desktop:p-0" style={{flex:0.5}}>
                    <h3 className="font-semibold text-[14px] text-[#42B8BD] desktop:text-[24px]">OUR STORY</h3>
                    <h1 className="font-semibold text-[24px] mt-[12px] desktop:mt-[24px] text-white desktop:text-[40px]" style={{lineHeight:'120%'}}> <span className="text-primary opacity-40">How we’re building <br/></span>
                    Africa’s Biggest Platform</h1>
                    <p className="text-[13px] mt-[16px] desktop:mt-[24px] text-white opacity-90 desktop:text-[16px]">Synergy is an African technology start-up on a mission to empower young Nigerians in the digital era, helping them navigate life and unlock their full potential. 

                    <br/> <br/>Our dedicated community offers vital resources, opportunities, and connections for young people to thrive and succeed.

                    <br/> <br/>We firmly believe that youth-driven innovation is the key to progress. That's why we are transforming the NYSC experience for young graduates, making it easier, better, more exciting, and ultimately more rewarding. Get ready to embark on an unforgettable Youth Service Adventure with us!</p>
                </div>
                <div className={`grid place-items-center mid:flex mid:items-center overflow-hidden animate__animated animate__slow ${isAnimated ? 'animate__fadeInLeft' : ''}`} style={{flex:0.5}} ref={ref}>
                    <img src="/images/love-pics.svg" alt="people smiling"  className={`w-[90%] h-[90%] mid:w-[100%] mid:h-[100%]`}/>
                </div>
            </div>
            <div className="py-[40px] px-[24px] tab:text-center mid:p-[80px] desktop:w-[918px] mx-auto desktop:px-0">
                <h3 className="font-semibold text-[14px] text-[#42B8BD] tab:text-center tab:text-[24px]">OUR VISION</h3>
                <h1 className="font-semibold text-[24px] mt-[12px] text-white tab:text-[40px]"><span className="opacity-40">A More</span> <span className="opacity-100">Connected</span> <span className="opacity-40">Africa</span> </h1>
                <p className="text-[13px] mt-[16px] text-white opacity-90 tab:text-[16px] tab:text-center">Synergy is an African technology start-up on a mission to empower young Nigerians in the digital era, helping them navigate life and unlock their full potential. 

                <br/> <br/>Our dedicated community offers vital resources, opportunities, and connections for young people to thrive and succeed.

                <br/> <br/>We firmly believe that youth-driven innovation is the key to progress. That's why we are transforming the NYSC experience for young graduates, making it easier, better, more exciting, and ultimately more rewarding. Get ready to embark on an unforgettable Youth Service Adventure with us!</p>
            </div>
            <div className="px-[20px] pt-[32px] pb-[48px] mids:px-[180px] mid:pt-[80px] mid:pb-[120px] hidden">
                <div className="flex items-center justify-between tab:grid tab:grid-cols-2">
                    <div>
                        <h1 className="text-[24px] font-semibold text-white tab:text-[48px] desktop:text-[72px]">The <span className="text-[#ED7832]">Team</span></h1>
                        <p className="text-[12px]  text-white tab:text-[18px] desktop:text-[24px]">Young & Impact driven</p>
                    </div>
                    <div className="tab:grid tab:place-items-center animate__animated animate__slow animate__backInLeft tab:h-[100%]">
                        <img src="/images/logo-flag.png" alt="logo" className={`tab:h-[100%] animate__animated animate__slow h-[40px] tab:h-[80px] desktop:w-[80px] tab:ml-auto ${flagView ? 'animate__swing':''}`} ref={flag} />
                    </div>
                </div>
                <div className="mt-[32px] desktop:mt-[96px] grid grid-cols-3 gap-[32px] mini:grid-cols-4  desktop:gap-[64px]">
                    <div className="text-center flex flex-col items-center">
                        <div className="w-full">
                        <img src="/images/team1.png" alt="team member" className="w-full" />
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Ebubechukwu Agnes</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="text-center flex flex-col items-center">
                        <div className="w-full">
                        <img src="/images/team2.png" alt="team member" className="w-full"/>
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Oyebamiji Oluwasola</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="text-center flex flex-col items-center">
                        <div className="w-full">
                        <img src="/images/team3.png" alt="team member" className="w-full" />
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Adese Samson</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="text-center flex flex-col items-center">
                        <div className="w-full">
                        <img src="/images/team4.png" alt="team member" className="w-full" />
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Josh Michael</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="text-center flex flex-col items-center mini:hidden">
                        <div className="w-full">
                        <img src="/images/team5.png" alt="team member" className="w-full" />
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Jicholia Oyebola</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="text-center flex flex-col items-center mini:hidden">
                        <div className="w-full">
                        <img src="/images/team6.png" alt="team member" className="w-full"/>
                        </div>
                        <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Emmanuel Amuneke</p>
                        <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                    </div>
                    <div className="mini:grid mini:grid-cols-2 hidden justify-center gap-x-[64px] mx-auto w-[50%]" style={{gridColumn:'-1/1'}}>
                        <div className="text-center flex flex-col items-center">
                            <div className="w-full">
                            <img src="/images/team5.png" alt="team member" className="w-full" />
                            </div>
                            <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Jicholia Oyebola</p>
                            <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                        </div>
                        <div className="text-center flex flex-col items-center">
                            <div className="w-full">
                            <img src="/images/team6.png" alt="team member" className="w-full" />
                            </div>
                            <p className="text-[9.75px] phones:text-[13px] mini:text-[19.5px] font-semibold text-white opacity-90 mt-[12px]">Emmanuel Amuneke</p>
                            <p className="text-[8.9px] phones:text-[11px] mini:text-[18px] text-[#FBA599]">Ethical Hacker</p>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div className="py-[16px] px-[24px] phone:px-[80px] hidden">
                <div className="px-[24px] py-[48px] bg-[#4FACB0] rounded-[32px] flex flex-col gap-y-[16px] phone:grid phone:grid-cols-2 phone:gap-x-[20px] mid:gap-x-0 desktop:px-[80px] desktop:py-[51px]">
                    <div className="phone:grid place-items-start">
                        <h1 className="font-semibold text-[48px] join-synergy1 phone:text-[60px] desktop:text-[96px] phone:w-[50%] my-auto">Join <br/> <span className="text-[#024355]">Synergy</span></h1>
                    </div>
                    <div>
                        <p className="font-medium text-[12px] phone:text-[18px] text-justify" style={{lineHeight:'170%'}}>Ready to unlock your full potential and seize exciting opportunities? Join Synergy today and register on our revolutionary app to embark on a transformative journey towards personal and professional success.
                        Synergy is more than just an app—it's your gateway to a world of possibilities. </p>
                        <div className="mt-[23px]">
                            <button className={`py-[8px] px-[12px] text-[#00141B] flex items-center gap-x-[4px] rounded-[4px] text-[12px] phone:text-[16px] font-semibold animate__animated animate__slow ${buttonView ? 'animate__headShake': ''}`} style={{border:'1px solid #00141B', transition:'all 0.3s ease-in-out'}} ref={start}>
                                Get Started
                                <ArrowRightBlack />
                            </button>
                            {/* <Buttons text={'Get Started'} color={'#00141B'} border={'1px solid #00141B'} padding={'8px 12px'} icons={<ArrowRightBlack />} /> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Foot />
        </div>
    )
}