import React from 'react'
import Navigation from '../components/nav/navigation'
import Footers from '../components/footer/footers'
import Foots from '../components/footer/foots'

export default function Privacy() {
    return (
        <>
            <div className='bg-backgroundDark desktop:pt-6 pt-3 desktop:px-[81px] px-6'>
                <Navigation />  
                <div className='desktop:py-6 py-[18px] desktop:pl-6 pl-[18px] desktop:mt-6 mt-[39px] bg-background'>
                    <h1 className='mb-3 desktop:text-[40px] text-[30px] font-medium capitalize text-gradient'>Privacy policy</h1>
                    <p className='font-medium text-green-600 desktop:text-[16px] text-[12px]'>Last updated 10 days ago</p>
                </div>
                <div className='mt-12 pb-[72px] flex flex-col gap-10'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-montaga text-[32px] text-light ml-[14px]'>1. Introduction</h1>
                        <p className='leading-[140%] text-light text-justify'>Lorem ipsum dolor sit amet consectetur. Pharetra lectus odio facilisi at. Sed mi habitant a sapien arcu. Nunc vitae at tempus suspendisse. Suspendisse orci scelerisque non est volutpat. Non gravida pellentesque consequat felis risus penatibus. Et mauris eu purus faucibus purus. Consectetur vitae hendrerit sit fringilla commodo suscipit aliquam tincidunt iaculis. Congue donec ut purus platea. Tempus augue molestie risus sit lectus habitant et gravida. Ullamcorper elementum lorem proin mollis nunc nunc ipsum porttitor. Egestas mi et etiam rhoncus gravida volutpat tristique lectus velit. In morbi nulla aenean felis vestibulum. Orci auctor vitae morbi gravida enim scelerisque dui feugiat. Feugiat faucibus quis dolor massa est diam duis feugiat. Tellus nunc feugiat vel lacus. Ultricies id risus arcu consequat egestas sit sagittis mattis nunc. Consequat parturient sed arcu hendrerit consequat. Nullam lectus sit euismod odio volutpat cursus tristique placerat. Quis volutpat sagittis non tortor enim adipiscing quis volutpat.</p>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-montaga text-[32px] text-light ml-[14px]'>2. Acceptance of Terms</h1>
                        <p className='leading-[140%] text-light text-justify'>Lorem ipsum dolor sit amet consectetur. Pharetra lectus odio facilisi at. Sed mi habitant a sapien arcu. Nunc vitae at tempus suspendisse. Suspendisse orci scelerisque non est volutpat. Non gravida pellentesque consequat felis risus penatibus. Et mauris eu purus faucibus purus. Consectetur vitae hendrerit sit fringilla commodo suscipit aliquam tincidunt iaculis. Congue donec ut purus platea. Tempus augue molestie risus sit lectus habitant et gravida. Ullamcorper elementum lorem proin mollis nunc nunc ipsum porttitor. Egestas mi et etiam rhoncus gravida volutpat tristique lectus velit. In morbi nulla aenean felis vestibulum. Orci auctor vitae morbi gravida enim scelerisque dui feugiat. Feugiat faucibus quis dolor massa est diam duis feugiat. Tellus nunc feugiat vel lacus. Ultricies id risus arcu consequat egestas sit sagittis mattis nunc. Consequat parturient sed arcu hendrerit consequat. Nullam lectus sit euismod odio volutpat cursus tristique placerat. Quis volutpat sagittis non tortor enim adipiscing quis volutpat.</p>
                    </div>
                </div>
                <div className='mt-[94px] desktop:block hidden'>
                    <Footers />
                </div>    
            </div>
            <Foots />
        </>
    )
}
