import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/moth.svg'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { signUpValidationSchema } from '../../libs/validationSchemas';
import { ReactComponent as Eye } from '../../assets/svg/eye.svg';
import Buttons from '../button';

export default function SignUpForm() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (values, { resetForm }) => {
        // You can perform form submission logic here
        console.log(values);
        navigate('/otp');
        // Reset the form after submission
        resetForm();
    };

    return (
        <div className='desktop:w-[58%] w-full px-6 py-[100px]'>
            <div className='flex flex-col gap-6 items-center'>
                <Logo />
                <div>
                    <h1 className='text-center text-[26px] font-bold text-white'>Join Synergy</h1>
                    <h3 className='mt-[6px] text-[15px] text-light text-center'>Achieve big dreams. Together</h3>
                </div>
            </div>
            <div className='mt-10'>
                <Formik
                    initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
                    validationSchema={signUpValidationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <div className="flex flex-col mb-4 gap-2">
                            <label htmlFor="name" className="text-[15px] capitalize text-green-300">
                                First name
                            </label>
                            <Field
                                type="text"
                                id="firstName"
                                name="firstName"
                                className="border-gray text-white bg-background border-[0.8px] rounded-[16px] px-[35px] py-3"
                            />
                            <ErrorMessage name="firstName" component="div" className="text-[#F56A6A]" />
                        </div>
                        
                        <div className="flex flex-col mb-4 gap-2">
                            <label htmlFor="name" className="text-[15px] capitalize text-green-300">
                                Last name
                            </label>
                            <Field
                                type="text"
                                id="lastName"
                                name="lastName"
                                className="border-gray text-white bg-background border-[0.8px] rounded-[16px] px-[35px] py-3"
                            />
                            <ErrorMessage name="lastName" component="div" className="text-[#F56A6A]" />
                        </div>

                        <div className="flex flex-col mb-4 gap-2">
                            <label htmlFor="email" className="text-[15px] capitalize text-green-300">
                                Email
                            </label>
                            <Field
                                type="email"
                                id="email"
                                name="email"
                                className="border-gray text-white bg-background border-[0.8px] rounded-[16px] px-[35px] py-3"
                            />
                            <ErrorMessage name="email" component="div" className="text-[#F56A6A]" />
                        </div>
                        
                        <div className="flex flex-col mb-6 gap-2">
                            <label htmlFor="password" className="text-[15px] capitalize text-green-300">
                                Password
                            </label>
                            <div className="relative">
                                <Field
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    name="password"
                                    className="border-gray w-full text-white bg-background border-[0.8px] rounded-[16px] px-[35px] py-3"
                                />
                                <div
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    <Eye />
                                </div>
                            </div>
                            <ErrorMessage name="password" component="div" className="text-[#F56A6A]" />
                        </div>

                        <p className='text-[12px] text-light mb-8'>By creating an account, I accept the <span className='underline cursor-pointer' onClick={() => navigate('/terms&condition')}>Terms & Conditions</span> And <span className='underline cursor-pointer' onClick={() => navigate('/privacypolicy')}>Privacy policy</span>.</p>
                        
                        <Buttons text='Continue' type='submit' hoverColor='#F56A6A' width='100%' weight='600' padding='14px 48px' color='#00141B' bgColor='#FBA599' />
                    
                        <p className='mt-[23px] text-white text-center'>Already a member? <span className='text-pink'>Log In</span></p>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}
