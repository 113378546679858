import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Menu } from '../../assets/svg/menu2.svg'
import Logo from '../../assets/img/logo3.png'
import Buttons from '../button'

export default function Navlight({buttons, setHamburger}) {
    const navigate = useNavigate();

    const getStarted = () => {
        navigate('/getstarted');
    }

    return (
        <div className='desktop:py-6 py-4 desktop:px-10 px-6 flex justify-between items-center navlight'>
        <div className='flex items-center gap-16 cursor-pointer'>
            <img src={Logo} alt='synerygy logo' onClick={() => navigate('/')} className='w-[108px]'/>
            <div className='desktop:flex hidden gap-8 text-[16.5px] font-medium capitalize text-background cursor-pointer'>
                <p onClick={() => navigate('/about')}>About us</p>
                <p onClick={() => navigate('/#connection')}>Success stories</p>
                <p onClick={() => navigate('/partnership')}>Partnership</p>
            </div>
        </div>
       {buttons && <div className='desktop:flex hidden gap-2'>
            <Buttons text='Job board' bgColor='inherit' color='#00141B' border='1px solid #00141B' hoverColor='#00141B' hoverText='#FFFFFF'/>
            <Buttons text='get started' bgColor='#00141B' color='#FFFFFF' functions={getStarted} hoverColor='#024355' hoverText='#FFFFFF'/>
        </div>}
        <Menu className='desktop:hidden block' onClick={() => setHamburger(true)}/>
        </div>
    )
}
