import React from 'react'
import { ReactComponent as Quote } from '../../assets/svg/quote.svg'
import Success from '../../assets/img/success.png'

export default function Successtart() {
    return (
        <div className='flex flex-col-reverse success-box justify-center mt-[131px]'>
            <div className='flex border-right py-8 px-[29px]'>
                <Quote className='w-[124px] h-[45px] -mt-3'/>
                <div className='flex flex-col gap-[15px]'>
                    <p className='text-justify text-[12.75px] leading-[150%] font-medium text-white font-raleway'>Synergy has been a lifesaver for me! As a recent graduate, I was struggling to find a job that matched my skills and interests. But with the help of Synergy's job search platform, I was able to find my dream job within weeks.</p>
                </div>
            </div>
            <div className='mt-[50px] flex flex-col justify-center items-center'>
                <img src={Success} alt='synergy success' />
                <p className='mt-6 text-[19.5px] font-raleway font-semibold text-white opacity-[0.9]'>Ebubechukwu Agnes</p>
                <p className='text-[17.8px] font-raleway text-green-150 text-justify'>Ethical Hacker</p>
            </div>
        </div>
    )
}
