import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Frame from '../../assets/img/frame3.png'

export default function Joinexcitingcircles() {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        if (inView) {
          setIsAnimated(true);
        }
    }, [inView]);

    return (
        <div className='desktop:px-[80px] px-[18px] desktop:pt-[62px] pt-[50px] desktop:pb-[98px] pb-[50px] flex mids:flex-row flex-col mids:gap-20 gap-10 items-center'>
            <div className='mid:w-[50%]'>
                <img src={Frame} alt='synergy frame' ref={ref} className={`animate__animated ${isAnimated ? 'animate__fadeInLeft' : ''}`} />
            </div>
            <div className='flex flex-col mids:gap-6 gap-3 mid:w-[50%]'>
                <h2 className='tab:text-[40px] text-[24px] mids:text-start text-center font-bold text-light'>Enjoy the flexibility of On-demand</h2>
                <p className='mids:text-justify text-center tab:text-[18px] text-[12px] text-white leading-[150%]'> Reclaim your valuable time. With our pickup and delivery service, you can focus on what matters most while we take care of your laundry needs.</p>
            </div>
        </div>
    )
}
