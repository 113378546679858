import React from 'react'
import { ReactComponent as Arrow } from '../../assets/svg/arrow.svg'
import { ReactComponent as Arrow2 } from '../../assets/svg/arrow2.svg'
import { ReactComponent as Rocket } from '../../assets/svg/rocket.svg'
import { ReactComponent as Apple } from '../../assets/svg/apple.svg';
import { ReactComponent as Googleplay } from '../../assets/svg/google-play.svg';
import Frame from '../../assets/img/Vector.png';
import Frame2 from '../../assets/img/vector2.png';
import Herocard from './herocard'
import Buttons from '../button'
import { Link } from 'react-router-dom';

export default function Hero() {


    return (
        <div className='desktop:mt-[17px] mt-[21px] hero'>
            <div className='flex justify-between mid:gap-0 gap-[50px]'>
                <div>
                    <Arrow className='w-[110px] mid:w-auto'/>
                    <img src={Frame2} alt='vector' className='mid:-mt-[70px] -mt-[50px] absolute mid:w-auto mid:h-auto w-[200px] h-[200px]' />
                </div>
                
                <div className='mr-6 absolute right-0'>
                    <Rocket className='w-full mid:w-auto animate__animated animate__rollIn animate__slow' />
                </div>
            </div>
            
            <div className='desktop:mt-[6%] mid:mt-12 mt-[100px] flex flex-col justify-center mid:px-0 px-[50px]'>
                <div className='flex justify-center'>
                    <div className='w-[767px] flex flex-col gap-6'>
                        <h1 className='text-primary text-center tab:text-[61px] text-[32px] font-bold capitalize animate__animated animate__pulse animate__slow'>Experience Freshness and Convenience with Laundreo</h1>
                        <h2 className='text-white text-center tab:text-[17px] text-[12px] font-medium capitalize'>Professional Laundry Services Tailored Just For You</h2>
                    </div>
                </div>
                <div className='animate__animated animate__fadeInUp animate__slower mt-12 flex gap-6 justify-center mid:flex-row flex-col items-center'>
                    <Link><Buttons text='Get on Iphone' hoverColor='#00141b' hoverBorder='#00141b' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #FCFDFD' icons={<Apple />} /></Link>
                   <Link> <Buttons text='Get on Android' hoverColor='#00141b' hoverBorder='#00141b' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #FCFDFD' icons={<Googleplay />} /></Link>
                    {/* <Link to={'https://apps.apple.com/ng/app/synergy-ng/id6447760204'}><Buttons text='Get on Iphone' hoverColor='#024355' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #FCFDFD' icons={<Apple />} /></Link>
                   <Link to={'https://play.google.com/store/apps/details?id=com.synergyng.synergy'}> <Buttons text='Get on Android' hoverColor='#024355' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#040404' border='1px solid #FCFDFD' icons={<Googleplay />} /></Link> */}
                </div>
            </div>
            
            <div className='flex justify-between desktop:pl-20 desktop:pr-14 pr-6 desktop:mt-0 mt-10 items-center'>
                <div className='mt-[10px]'>
                    <Arrow2 className='mt-2'/>
                </div>
                <img src={Frame} className='w-[109px] tab:w-auto animate__animated animate__slideInUp animate__slow' />
            </div>
        </div>
    )
}
