import React from 'react'
import Nav from './nav/nav'
import { ReactComponent as Left } from '../assets/svg/chevron-left.svg'
import Navigation from './nav/navigation'

export default function Box({ children, nav, user }) {
    
    return (
        <div className='bg-background flex flex-col box-form'>
            {nav === 'nav' && <Nav user={user} />}
            <Navigation />
            <div className='place-self-center h-full justify-center flex flex-col'>
                <div className='flex gap-2 items-center justify-center mt-[73px]'>
                    <Left className='text-[#F9FAFB] opacity-50' />
                    <p className='text-[18px] text-[#F9FAFB] opacity-50'>Go back</p>
                </div>
                <div className={`${!nav && 'mt-[40px]'} py-10 px-20 rounded-[24px] mb-[100px] desktop:border desktop:border-green-50`}>
                    {children}
                </div>
            </div>
        </div>
    )
}
