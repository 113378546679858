import React from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import SignUpForm from '../components/forms/signUpForm';
import Successtart from '../components/success/successtart';
import Navigation from '../components/nav/navigation';

export default function Auth() {
    return (
        <div className='bg-background auth'>
            <Navigation />
            <div className="w-full flex">
                <div className="w-[43.1%] bg-backgroundBlack py-[56px] px-[120px] desktop:flex flex-col hidden">
                    <h3 className='text-center text-[20px] font-semibold text-white opacity-[0.5]'>Success Stories</h3>
                    <h2 className='mt-[9.9px] text-center text-[26px] font-extrabold text-white'>How Synergy is changing lives</h2>
                    <div className='flex-1 flex justify-center synergy-swiper'>
                        <Swiper
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination]}
                        >
                            <SwiperSlide>
                                <Successtart />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Successtart />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Successtart />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Successtart />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="desktop:flex-1 flex w-full">
                    <div className="w-full flex-1 flex desktop:items-center justify-center">
                        <SignUpForm />
                    </div>
                </div>
            </div>
        </div>
    )
}
