import React from 'react'
import { useLocation } from 'react-router-dom'
import Box from '../components/box'
import Buttons from '../components/button';
import { ReactComponent as Icon } from '../assets/svg/welcome.svg';
import { ReactComponent as Icon2 } from '../assets/svg/rocket2.svg';
import { ReactComponent as Apple } from '../assets/svg/apple.svg';
import { ReactComponent as Googleplay } from '../assets/svg/google-play.svg';

export default function Welcome() {
    const location = useLocation();
    const user = location.state?.username;
    
    return (
        <Box nav='nav1' user={user}>
            <div className='flex flex-col gap-12'>
                <Icon className='flex self-center'/>
                <div>
                    <h1 className='mb-2 text-white text-center text-[32px] font-semibold'>Welcome to synergy</h1>
                    <p className='text-center text-white opacity-[0.6] desktop:block hidden'>Download the app to explore</p>
                </div>
                <div className='desktop:hidden flex justify-center'>
                    <Buttons text='Let’s take you places' icons={<Icon2 />} hoverColor='#F56A6A' type='submit' width='334px' weight='600' padding='14px 48px' color='#00141B' bgColor='#FBA599'/>
                </div>
                <div className='desktop:flex gap-6 justify-center flex-row items-center hidden'>
                    <Buttons text='Get on Iphone' hoverColor='#024355' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#00141B' border='1px solid #024355' icons={<Apple />} />
                    <Buttons text='Get on Android' hoverColor='#024355' width='238px' weight='500' size='13px' padding='16px 24px' color='#FFFFFF' bgColor='#00141B' border='1px solid #024355' icons={<Googleplay />} />
                </div>
            </div>
        </Box>      
    )
}
