import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Box from '../box'
import Buttons from '../button'
import { OtpInput } from '../input'

export default function OtpForm() {
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const [otp, setOtp] = useState();
    const [countdown, setCountdown] = useState(60);

    useEffect(() => {
        const interval = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
    }, []);
    
    useEffect(() => {
        if (countdown === 0) {
          setCountdown(60);
        }
    }, [countdown]);

    const handleSubmit = () => {
        navigate('/username');
    }

    return (
        <Box>
            <h1 className='mb-6 text-white text-center text-[32px] font-semibold'>Confirm your Email</h1>
            <h3 className='text-center text-white opacity-[0.6]'>We sent your OTP to tosinjegede@yahoo.com.</h3>
            <h3 className='text-center text-white opacity-[0.6]'>Please enter below</h3>
            <form onSubmit={handleSubmit}>
                <div className='mt-12 mb-[72px] flex space-x-4'>
                    {Array(6)
                        .fill()
                        .map((_, idx) => {
                            return (
                                <OtpInput
                                    key={idx}
                                    ref={(el) => (inputRefs.current[idx] = el)}
                                    placeholder="-"
                                    onChange={(e) => {
                                        const value = e?.target?.value;
                                        if (value === "") return;
                                        if (idx === 5 && value === "") return;
                                        inputRefs?.current[idx + 1]?.focus();
                                        const inputValues = inputRefs.current?.map((value) => value?.value);
                                        const otpString = inputValues.filter(Boolean).join("");
                                        const otpNumber = parseInt(otpString, 10);
                                        setOtp(otpNumber);
                                    }}
                                    fullWidth
                                />
                            );
                        })
                    }
                </div>
                <div className='flex justify-center'>
                    <Buttons text='Verify' hoverColor='#F56A6A' type='submit' width='334px' weight='600' padding='14px 48px' color='#00141B' bgColor='#FBA599'/>
                </div>
            </form>
            <div className='mt-6 flex gap-1 justify-center'>
                <p className='text-[14px] text-light'>Didn’t get the code?</p>
                <p className='text-[14px] font-semibold text-light'>Resend Code <span className='text-pink'>({countdown}s)</span></p>
            </div>
        </Box>      
    )
}
