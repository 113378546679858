import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './routes/home';
import Terms from './routes/terms';
import Privacy from './routes/privacy';
import Contact from './routes/contact';
import Auth from './routes/auth';
import OtpForm from './components/forms/otpForm';
import UsernameForm from './components/forms/usernameForm';
import Welcome from './routes/welcome';
import About from './routes/about';
import GetAccess from './routes/get-access';
import Partnership from './routes/partnership';
import LearnMore from './routes/learn-more';
import FAQ from './routes/FAQ';

export default function App() {
  const location = useLocation();
  const [hamburger, setHamburger] = useState(false)
  const referralCodeRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const extractReferralToken = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const referralToken = searchParams.get('referral_token');
      if (referralToken) {
        referralCodeRef.current = referralToken;
        referral();
      }
    };

    extractReferralToken();
  }, []);
  
  const referral = async (referralToken) => {
    const apiUrl = `https://api.synergyng.app/v1/platform/onboard`;
    const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjE2NiwiaWF0IjoxNjkwMjk2NDI4fQ.js9zS9NPuB412gx4qFKgKU2Ug33MbHj7RZFB-I0T6iQ';
    const deviceModel = 'web';
    const params = { referral_token: referralToken };

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          device_model: deviceModel,
        },
        params,
      });
      window.open(response.data.link, "_blank");
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  return (
    <Routes>
      <Route exact path='/' element={<Home hamburger={hamburger} setHamburger={setHamburger} />} />
      <Route path='/terms&condition' element={<Terms />} />
      <Route path='/privacypolicy' element={<Privacy />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/getstarted' element={<Auth />} />
      <Route path='/otp' element={<OtpForm />} />
      <Route path='/username' element={<UsernameForm />} />
      <Route path='/welcome' element={<Welcome />} />
      <Route path='/about' element={<About hamburger={hamburger} setHamburger={setHamburger} />} />
      <Route path='/get-access' element={<GetAccess hamburger={hamburger} setHamburger={setHamburger} />} />
      <Route path='/partnership' element={<Partnership hamburger={hamburger} setHamburger={setHamburger} />} />
      <Route path='/learn-more' element={<LearnMore />} />
      <Route path='/faq' element={<FAQ hamburger={hamburger} setHamburger={setHamburger} />} />
    </Routes>
  )
}
