import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Frame from '../../assets/img/frame2.png'
import Buttons from '../button'
import { useNavigate } from 'react-router-dom';

export default function Landdreamjob() {
    const navigate = useNavigate();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        if (inView) {
          setIsAnimated(true);
        }
    }, [inView]);

    const handleStart = () => {
        navigate('/getstarted')
    }

    return (
        <div className='desktop:p-20 px-[18px] py-[50px] flex mids:flex-row flex-col-reverse mids:gap-20 gap-10 items-center'>
            <div className='flex flex-col mid:w-[50%] mids:gap-6 gap-3'>
                <h2 className='tab:text-[40px] text-[24px] mids:text-start text-center font-bold text-light'>Fresh, Clean, and Crisp</h2>
                <p className='mids:text-justify text-center tab:text-[18px] text-[12px] text-white leading-[150%]'>Say goodbye to dingy clothes! Our advanced washing techniques and quality detergents ensure your garments come out looking and smelling fresh every time.</p>
                <div className='mids:mt-12 mt-6 mids:justify-start justify-center flex hidden'>
                    <Buttons text='Get Started' hoverColor='#42b8bd7a' hoverText='#FFFFFF' functions={handleStart} width='200px' weight='500' size='13px' padding='16px 24px' color='#42B8BD' bgColor='#00141B' border='1px solid #42B8BD' />
                </div>
            </div>
            <div className='mid:w-[50%]'>
                <img src={Frame} alt='synergy frame' ref={ref} className={`animate__animated ${isAnimated ? 'animate__fadeInRight' : ''}`}/>
            </div>
        </div>
    )
}
