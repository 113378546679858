import React from "react";
import Navlight from "../components/nav/navlight";
import Buttons from "../components/button";
import PartnershipForm from "../components/forms/partnershipForm";
import { useNavigate } from "react-router-dom";
import Hamburger from "../components/hamburger";



export default function Partnership({hamburger, setHamburger}){

    const navigate = useNavigate()

const getStarted = () => {
    navigate('/getstarted')
}
    return (
        <>
        {hamburger && <Hamburger setHamburger={setHamburger} />}
                <div className="mid:grid grid-cols-7">
            <div className="mid:col-span-4">
             <Navlight buttons={false} setHamburger={setHamburger} />
            <div className="pb-[40px] mid:pb-[84px] pt-[36px] mid:pt-[100px] px-[32px] desktop:pl-[77px] desktop:pr-[133px]">
              <div className="mx-auto">
              <h1 className="font-bold text-[24px] mid:text-[32px] opacity-90">Partner with us</h1>
                <p className="text-[12px] mid:text-[16px]" style={{lineHeight:'170%'}}>At Synergy, we specialize in finding the perfect collaboration for your company. With our deep understanding of your unique needs, we guide you through a seamless talent acquisition journey.</p>
              </div>
                <PartnershipForm />
            </div>
            </div>
            <div className="bg-[#F6F6F6] hidden mid:block mid:col-span-3 relative">
                <div className="flex justify-end gap-x-[14px] py-[24px] px-[40px] hidden">
                <Buttons text='Job board' bgColor='inherit' color='white' border='1px solid white'   padding={'12px 17px'}/>
                <Buttons text='get started' bgColor='white' color='#00141B' functions={getStarted} padding={'12px 17px'}/>
                </div>
                <img src="/images/handshake.svg" alt="handshake" className="absolute bottom-0" style={{mixBlendMode:'hard-light'}}/>

            </div>
        </div>
        </>
    )
}